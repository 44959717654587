$(document).ready(function(){
    /*Home Banner Slider*/
    $('.slider').owlCarousel({
        autoplay: false,
        /*animateOut: 'fadeOut',
        autoplayTimeout: 5000,
        autoplayHoverPause: true,*/
        items:1,
        merge:true,
        loop:false,
        margin:0,
        nav:false,
        video:true,
        lazyLoad:true,
        center:true,
        mouseDrag:false
    });

    /*function onIntCarousel(){
        console.log('asdasdsad');
    }*/

    /*Product Range Slider*/
    $('#product_range .container, .tst_slider_hld, .pr_slider').owlCarousel({
        items:1,
        merge:true,
        loop:false,
        margin:0,
        video:true,
        lazyLoad:false,
        center:true,
        mouseDrag:false 
    });

    if(winWT <= 980){
        //$('#product_range .indoor').insertAfter('#product_range .range');
        var productItemArr = $('#product_range .item');
        var productSlideControll = $('#product_range').find('.owl-controls');
        //var proControllRef = $(productSlideControll).remove();
        function swapProductInnerDiv(i){
            var currItem = $(productItemArr[i]);
            console.log(currItem);
            var currIndoorDiv = currItem.find('.indoor');
            var currRangeDiv = currItem.find('.range');
            $(currIndoorDiv).insertAfter(currRangeDiv);

        }
        for(var i=0; i<productItemArr.length; i++){
            swapProductInnerDiv(i);
        }


    }

    /*$('.video_link').magnificPopup({
        type: 'iframe',
        closeOnBgClick: true,
        modal: true,
        gallery: {
            enabled: false
        }
    });*/

    $('.video_play').on('click', function(){
        $('.slider').trigger('stop.owl.autoplay');
    });

    $('.mfp-iframe-holder .mfp-close').on('click', function(){
        $('.slider').trigger('play.owl.autoplay');
    });

});